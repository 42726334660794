import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserData } from './../models/auth/user-data.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private spinner_status = false;

  public _apiToken: string = '';

  public _userData: UserData = new UserData();

  public _message_list: any;

  constructor(public snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: [className],
    });
  }

  public showSpinner() {
    this.spinner_status = true;
  }

  public hideSpinner() {
    this.spinner_status = false;
  }

  public getSpinnerStatus() {
    return this.spinner_status;
  }

  public setApiToken(token: string) {
    this._apiToken = 'Bearer ' + token;
  }

  public getApiToken(): string {
    return this._apiToken;
  }

  public checkApiToken(): boolean {
    let isExit = false;
    let apitoken = JSON.parse(localStorage.getItem('apitoken'));
    let tempoToken = this._apiToken;
    if (tempoToken != null && tempoToken != undefined && tempoToken != '') {
      isExit = true;
    } else if (apitoken != null && apitoken != undefined && apitoken != '') {
      this._apiToken = apitoken;
      isExit = true;
    }
    return isExit;
  }

  public getUserData(): UserData {
    return this._userData;
  }

  public setUserData(data: UserData) {
    this._userData = data;
  }

  public checkUserData(): boolean {
    let isExit = false;
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (userData != null && userData != undefined && userData != '') {
      this._userData = userData;
      isExit = true;
    }

    return isExit;
  }
}
