export class UserData {
  email: string;
  user_type: string;
  id: string;
  name: string;
  logo: string;

  constructor() {
    this.email = "";
    this.user_type = "";
    this.id = "";
    this.name = "";
    this.logo = "";
  }
}
